.box {
  width: 200px;
  height: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  box-shadow: -6px -4px 15px #ffffff, 4px 4px 15px #e8e8e8;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.box-active {
  background-color: green;
}
