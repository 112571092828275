/* body {
  margin: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* .mantine-16fdnqw{
  margin:0px !important;
} */

.no_scroller::-webkit-scrollbar-thumb:hover {
  background-color: #2c752f;
}
.no_scroller::-webkit-scrollbar {
  width: 8px;
}

.no_scroller::-webkit-scrollbar-track {
  background: #eee6fc;
  opacity: 0.1;
}

.no_scroller::-webkit-scrollbar-thumb {
  background-color: #2c752f;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #2c752f;
}
*::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-track {
  background: #eee6fc;
  opacity: 0.1;
}

*::-webkit-scrollbar-thumb {
  background-color: #2c752f;
  border-radius: 10px;
}
