.leaf {
    /* width: 15px; */
    /* height: 100px; */
    /* background-color: red; */
    padding-left: 20px;
    position: relative;
    animation-name: example;
    animation-iteration-count: infinite;
    animation-duration: 2s ;
  }
  .leaf2 {
    /* width: 15px; */
    /* height: 100px; */
    /* background-color: red; */
    padding-right: 30px;
    position: relative;
    animation-name: example;
    animation-iteration-count: infinite;
    animation-duration: 2s ;
  }
  
  /* @keyframes example {
    0%   {left:0px; top:20px;}
    25%  { left:50px; top:0px;}
    50%  { left:50px; top:50px;}
    75%  { left:0px; top:50px;}
    100% { left:0px; top:0px;}
  } */

/* @keyframes example {
  0%   {left:0px; top:20px;}
  25%  { left:50px; top:0px;}
  50%  { left:50px; top:50px;}
  75%  { left:0px; top:50px;}
  100% { left:0px; top:0px;}
} */

/* @keyframes example {
0%   {left:0px; top:20px;}
25%  { left:50px; top:0px;}
50%  { left:50px; top:50px;}
75%  { left:0px; top:50px;}
100% { left:0px; top:0px;}
} */

@keyframes example {
0% {
  opacity: 1;
  transform: rotate(0deg);
  /* box-shadow: 0 0 0 -.5em currentcolor,
  0 0 0 -.5em currentcolor,
  0 0 0 -.5em currentcolor,
  0 0 0 -.5em currentcolor,
  0 0 0 -.5em currentcolor,
  0 0 0 -.5em currentcolor,
  0 0 0 -.5em currentcolor,
  0 0 0 -.5em currentcolor; */
}
100% {
  opacity: 1;
  transform: rotate(40deg);
  /* box-shadow: -1em -1em 0 -.35em currentcolor,
  0 -1.5em 0 -.35em currentcolor, */
  /* 1em -1em 0 -.35em currentcolor,
  -1.5em 0 0 -.35em currentcolor,
  1.5em -0 0 -.35em currentcolor,
  -1em 1em 0 -.35em currentcolor,
  0 1.5em 0 -.35em currentcolor,
  1em 1em 0 -.35em currentcolor; */
}
}
